// export const Base_URL = "http://localhost/Ticket-Counter-Backend/Users-API";
export const Base_URL = "https://ticketbay.in/Backend/Users-API";
// export const Image_URL = "http://localhost/Ticket-Counter-Backend";
export const Image_URL = "https://ticketbay.in/Backend";

export const Poster_Image_URL = `${Image_URL}/Posters`;
export const Profile_Image_URL = `${Image_URL}/Profile-Images`;
export const Member_Image_URL = `${Image_URL}/Members`;


// Auth URLs 
export const Register_URL = `${Base_URL}/register.php`;

export const Login_URL = `${Base_URL}/login.php`;

export const Profile_Images_URL = `${Base_URL}/fetch-profile-images.php`;

// user details 
export const User_Details_URL = `${Base_URL}/fetch-user-details.php`;

// Update profile image 
export const Profile_Image_Update_URL = `${Base_URL}/update-profile-pic.php`;

// Banners 
export const Banners_URL = `${Base_URL}/banner-movies.php`;

// All Shows 
export const Fetch_All_Shows_URL = `${Base_URL}/fetch-all-shows.php`;

// Filtered Shows 
export const Fetch_Filtered_Shows_URL = `${Base_URL}/fetch-filtered-movies.php`;

// All Theaters 
export const Fetch_Theaters_URL = `${Base_URL}/fetch-theaters.php`;

// Theaters dates 
export const Theater_Dates_URL = `${Base_URL}/theater-movies-date.php`;

// Theaters Shows 
export const Theater_Shows_URL = `${Base_URL}/theater-movies-info.php`;

// Movie details 
export const Movie_Details_URL = `${Base_URL}/movie-details.php`;

// Review submit 
export const Review_Submit_URL = `${Base_URL}/review-submit.php`;

// Theaters dates 
export const Movie_Dates_URL = `${Base_URL}/movie-dates.php`;

// Theaters dates 
export const Movie_Info_Dates_URL = `${Base_URL}/movie-details-info.php`;

// Fetch seat layout
export const Fetch_Seats_URL = `${Base_URL}/fetch-screen-layout.php`;

// Fetch Admin commission
export const Fetch_Admin_Commissions_URL = `${Base_URL}/fetch-admin-commissions.php`;

// Fetch Theater commission
export const Fetch_Theater_Commissions_URL = `${Base_URL}/fetch-theater-commission.php`;

// Fetch seat available
export const Fetch_Seats_Availble_URL = `${Base_URL}/fetch-screen-sections-seat-available.php`;

// Payment
export const Payment_URL = `${Base_URL}/payment-gateway-prod.php`;

// Fetch Booked seat
export const Fetch_Booked_Seats_URL = `${Base_URL}/fetch-booked-seats.php`;

// Fetch Upcoming Tickets
export const Fetch_Upcoming_Tickets_URL = `${Base_URL}/fetch-upcoming-tickets.php`;

// Fetch Previous Tickets
export const Fetch_Previous_Tickets_URL = `${Base_URL}/fetch-previous-tickets.php`;

// Fetch Cancelled Tickets
export const Fetch_Cancelled_Tickets_URL = `${Base_URL}/fetch-cancelled-tickets.php`;

// Fetch Tickets Details
export const Fetch_Tickets_Details_URL = `${Base_URL}/fetch-ticket-details.php`;

// Cancel Ticket
export const Cancel_Ticket__URL = `${Base_URL}/cancel-ticket.php`;

// Refund Status
export const Refund_Status__URL = `${Base_URL}/refund-status.php`;

// Contact Us
export const Contact_Us_URL = `${Base_URL}/contact.php`;

// Send OTP
export const Send_OTP_URL = `${Base_URL}/send-otp.php`;

// Verify OTP
export const Verify_OTP_URL = `${Base_URL}/verify-otp.php`;

// Change Password
export const Change_Password_URL = `${Base_URL}/change-password.php`;

// Change Password
export const Profile_Update_URL = `${Base_URL}/update-profile.php`;