import { useEffect, useState } from "react";
import ProfileImages from "../Modals/ProfileImagesModal";
import { Link, useLocation } from "react-router-dom";
import { SidebarWrapper } from "../Styles/Nav-Footer-Style";
import axios from "axios";
import { Profile_Image_URL, Send_OTP_URL, User_Details_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';


function Sidebar({profileModalShow, setProfileModalShow, userImage, setUserImage, setOtpEmail, setPasswordOtpModal, setLoading, setProfileUpdateModal}) {

    const location = useLocation();
    const [profileImgModalShow, setProfileImgModalShow] = useState(false);
    const [userDetails, setUserDetails] = useState([]);
    const [bookingListShow, setBookingListShow] = useState(false);


    const fetchUserDetails = () => {
        let loginStatus = localStorage.getItem("Login");

        if (loginStatus == "true") {
            let user = JSON.parse(localStorage.getItem("UserData"));
            axios.get(`${User_Details_URL}?user=${user.user}`)
            .then(res => {
                setUserDetails(res.data.user);
                setOtpEmail(res.data.user.email);
            })
            .catch(err => {
                console.error(err);
            });
        }
    };

    useEffect(() => {
        fetchUserDetails();
    }, []);


    useEffect(() => {
        if (profileModalShow) {
            fetchUserDetails();
        }

        if (!profileModalShow) {
            setProfileImgModalShow(false);
            setBookingListShow(false);
        }
    }, [profileModalShow]);

    useEffect(() => {
        // Close sidebar when navigating to a different page
        setProfileModalShow(false);
    }, [location]);

    const handleProfileImgModal = () => {
        setProfileImgModalShow(!profileImgModalShow);
    };

    const handlePasswordOtpModal = () => {
        setLoading(true);
        setProfileModalShow(false);
        const inputs = {
            email: userDetails.email
        }

        axios.post(Send_OTP_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            // console.log(res);
            toast.success('OTP has been sent successfully !!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setLoading(false);
            setPasswordOtpModal(true);
        })
        .catch(err => {
            console.log(err);
        })
    };

    function openProfileUpdateModal() {
        setProfileModalShow(false);
        setProfileUpdateModal(true);
    }


    const handleLogout = () => {
        localStorage.setItem("Login", false);
        localStorage.removeItem("UserData");
        localStorage.removeItem("location");
        setProfileModalShow(false);
        setUserDetails([]);
        setUserImage(null);
        window.location.reload();
    };

    return(
        <>
            <SidebarWrapper className={profileModalShow ? 'active' : ''}>
                <div className="close_btn" onClick={() => setProfileModalShow(false)}>
                    <a><i className="fa-solid fa-arrow-right-long"></i></a>
                </div>
                <div className="sidebar_inner">
                    <div className="profile_image_sec">
                        <div className="image_box">
                            <div className="box_inner">
                                <img src={`${Profile_Image_URL}/${userImage}`} alt="profile" />
                            </div>
                            <a className="edit_btn" onClick={handleProfileImgModal}><i className="fa-solid fa-pen"></i></a>
                        </div>

                        <h5>{userDetails.name}</h5>
                        <span onClick={openProfileUpdateModal}>Edit Profile<i className="fa-solid fa-pen-to-square"></i></span>
                    </div>
                    <div className="user_details_sec">
                        <li>
                            <span>Phone :</span>
                            <p>{userDetails.phone}</p>
                        </li>
                        <li>
                            <span>Email :</span>
                            <p>{userDetails.email}</p>
                        </li>
                        <li>
                            <span>City :</span>
                            <p>{userDetails.city}</p>
                        </li>
                        <li>
                            <span>State :</span>
                            <p>{userDetails.state}</p>
                        </li>
                    </div>
                    <div className="booking_list_btn">
                        <a onClick={handlePasswordOtpModal}><i className="fa-solid fa-lock"></i>Change Password<i className="fa-solid fa-arrow-right-long"></i></a>
                        <Link to="/booking-list"><i className="fa-solid fa-file-lines"></i>Your Tickets<i className="fa-solid fa-arrow-right-long"></i></Link>
                    </div>
                    <div className="logout_btn">
                        <a onClick={handleLogout}><i className="fa-solid fa-right-from-bracket"></i>Sign Out</a>
                    </div>
                </div>
            </SidebarWrapper>

            <ProfileImages profileImgModalShow={profileImgModalShow} setProfileImgModalShow={setProfileImgModalShow} userImage={userImage} setUserImage={setUserImage} />
        </>
    );
}


export default Sidebar;