import { useState } from "react";
import { SigninWrapper } from "../Styles/Modal-Style";
import axios from "axios";
import { Login_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';





function ModalSignin({showModal, handleSigninModal, handleSignupModal, setShowProfile, setEmailSelectModal}) {

    const [showPassword, setShowPassword] = useState(false);
    const [credentials, setCredentials] = useState({
        name: '',
        password: ''
    });

    const handleInput = (e) => {
        setCredentials({...credentials, [e.target.name]: e.target.value});
    }

    function handlePassword() {
        setShowPassword(!showPassword);
    }

    function closeModal() {
        handleSigninModal();
    }

    function redirectToSignUp() {
        handleSigninModal();
        handleSignupModal();
    }

    function openMailModal() {
        handleSigninModal();
        setEmailSelectModal(true);
    }

    function handleSubmit(e) {
        e.preventDefault();
        axios.post(Login_URL, credentials, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            // console.log('Login', res.data);
            let status = res.data.status;

            if(status == "200"){
                toast.success('Login successful !!!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                handleSigninModal();
                localStorage.setItem("Login", true);
                let userData = {
                    userId: res.data.userId,
                    user: res.data.user,
                }
                localStorage.setItem("UserData", JSON.stringify(userData));
                setShowProfile(true);
            }
        })
        .catch(err => {
            let status = err.response.data.status;
            if(status == "400") {
                toast.error('Invalid Credentials !!!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            } else if(status == "403") {
                toast.error(`User doesn't exists !!!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        })
    }

    return(
        <>
            <SigninWrapper className={showModal ? 'active' : ''}>
                <div className={`signin_box ${showModal ? 'active' : ''}`}>
                    <div className="close">
                        <a onClick={closeModal}><i className="fa-solid fa-xmark"></i></a>
                    </div>
                    <div className="box_left">
                        <form onSubmit={handleSubmit}>
                            <h3>!! Welcome !!</h3>
                            <div className="form_sec">
                                <div className="input_box">
                                    <input type="text" name="name" value={credentials.name || ""} onChange={handleInput} required />
                                    <span>Email / Mobile No.</span>
                                </div>
                                <div className="input_box">
                                    <input type={showPassword ? 'text' : 'password'} name="password" value={credentials.password || ""} onChange={handleInput} required />
                                    <span>Password</span>
                                    <a onClick={handlePassword}><i className={`fa-solid ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i></a>
                                </div>
                                <div className="forgot_remember">
                                    <label htmlFor="remember">
                                        <input type="checkbox" id="remember" />
                                        <span><i class="fa-solid fa-check"></i></span>
                                        <p>Remember Me</p>
                                    </label>
                                    <a onClick={openMailModal}>Forgot Password?</a>
                                </div>
                                <div className="form_btn">
                                    <button>Sign In</button>
                                </div>
                                <div className="redirect_box">
                                    <p>Don't have any account? <a onClick={redirectToSignUp}>Please Signup</a></p>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="box_right">
                        <img src="images/Signin-Icon.svg" alt="Icon" />
                    </div>
                </div>
            </SigninWrapper>
        </>
    );
}



export default ModalSignin;