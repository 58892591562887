import { useEffect, useState } from "react";
import { ErrorWrapper } from "../Styles/Success-Style";
import { useNavigate } from "react-router-dom";



function PaymentFailed() {

    const [countdown, setCountdown] = useState(3);
    const navigate = useNavigate();


    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        if (countdown === 0) {
            navigate('/home'); // Redirect to the home page
        }

        return () => clearInterval(interval);
    }, [countdown, navigate])

    return(
        <>
            <ErrorWrapper>
                <div className="wrapper_inner">
                    <div className="page_icon">
                        <img src="images/error.gif" alt="icon" />
                    </div>
                    <div className="page_content">
                        <h3>!! Oops !!</h3>
                        <h5>Something went wrong. Please try again after some times.</h5>
                        <p>Redirecting to home page in <span>{countdown} second{countdown > 1 && 's'}</span></p>
                    </div>
                </div>
            </ErrorWrapper>
        </>
    );
}


export default PaymentFailed;