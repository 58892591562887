import { useEffect, useState } from "react";
import { BookingWrapper } from "../Styles/Booking-Style";
import ModalTrailer from "../Modals/TrailerModal";
import ModalReview from "../Modals/ReviewModal";
import MovieTimingInfo from "../Modals/MovieTimingInfoModal";
import ModalBooking from "../Modals/BookingModal";
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from "axios";
import { Member_Image_URL, Movie_Details_URL, Poster_Image_URL } from "../API/Api";


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';





function MoviePage() {

    const [loginStatus, setLoginStatus] = useState(localStorage.getItem("Login") === "true");
    const [movieInfo, setMovieInfo] = useState([]);
    const [movieName, setMovieName] = useState('');
    const [theaterName, setTheaterName] = useState('');
    const [types, setTypes] = useState('');
    const [languages, setLanguages] = useState([]);
    const [rateButtonShow, setRateButtonShow] = useState(false);
    const [rateBox, setRateBox] = useState(false);
    const [shareButtons, setShareButtons] = useState(false);
    const [trailerShow, setTrailerShow] = useState(false);
    const [movieAbout, setMovieAbout] = useState('');
    const [casts, setCasts] = useState([]);
    const [crews, setCrews] = useState([]);
    const [trailer, setTrailer] = useState('');
    const [bookingBtnShow, setBookingBtnShow] = useState(false);
    const [selectedRating, setSelectedRating] = useState(0);
    const [reviewModalShow, setReviewModalShow] = useState(false);
    const [movieDatesModalShow, setMovieDatesModalShow] = useState(false);
    const [bookingModalShow, setBookingModalShow] = useState(false);
    const [selectedShowDetails, setSelectedShowDetails] = useState([]);
    const [bookedSeats, setBookedSeats] = useState([]);
    

    function handleRateBox() {
        setRateBox(!rateBox);
    }

    function handleShareButtons() {
        setShareButtons(!shareButtons);
    }

    const copyLink = () => {
        navigator.clipboard.writeText(window.location.href).then(() => {
            toast.success('Link Copied Successfull !!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }, (err) => {
            toast.error('Somthing Went Wrong !!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        });
    };

    const shareOnFacebook = () => {
        const url = encodeURIComponent(window.location.href);
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        window.open(facebookShareUrl, '_blank');
    };

    const shareOnWhatsapp = () => {
        const url = encodeURIComponent(window.location.href);
        const whatsappShareUrl = `https://wa.me/?text=${url}`;
        window.open(whatsappShareUrl, '_blank');
    };

    useEffect(() => {
        setRateButtonShow(loginStatus);
    }, [loginStatus]);

    useEffect(() => {

        let movie = localStorage.getItem("Movie_name");

        axios.get(`${Movie_Details_URL}?name=${movie}`)
        .then(res => {
            console.log(res);
            setMovieInfo(res.data.movie);
            setTypes(res.data.movie.types.split(', ').map(type => type.trim()).join(' / '));
            setLanguages(res.data.movie.languages.split(', ').map(lang => lang.trim()));
            setMovieAbout(res.data.movie.about);
            setCasts(res.data.casts);
            setCrews(res.data.crews);
            setTrailer(res.data.movie.trailer);
            if(res.data.movie.activity === "On Screen"){
                setBookingBtnShow(true);
            } else {
                setBookingBtnShow(false);
            }

            if(localStorage.getItem("showDetails")){
                let showDetails = JSON.parse(localStorage.getItem("showDetails"));
                // setLanguages(showDetails.language);
                setSelectedShowDetails(showDetails);
                setTheaterName(showDetails.theaterName);
                setMovieName(showDetails.movieName);
                localStorage.removeItem("showDetails");
            } else {
                setMovieName(localStorage.getItem("Movie_name"));
            }
        })
        .catch(err => {
            console.log(err);
        })


    }, [movieName]);

    const showData = () => {
        if(theaterName){
            setBookingModalShow(true);
        } else {
            setMovieDatesModalShow(true);
        }
    }

    const handleTrailer = () => {
        setTrailerShow(!trailerShow);
    };

    const handleStarClick = (rating) => {
        setSelectedRating(rating);
        setReviewModalShow(true);
    };

    return(
        <>
            <BookingWrapper>

                {
                    movieInfo && 
                    <div className="banner_sec">
                        <div className="sec_items">
                            <div className="item_left">
                                <div className="movie_box">
                                    <img src={`${Poster_Image_URL}/${movieInfo.poster_image}`} alt="poster" />
                                </div>
                            </div>
                            <div className="item_right">
                                <div className="item_inner">
                                    <h2>{movieInfo.name}</h2>
                                    <li>
                                        <span>{movieInfo.release_date}</span>
                                        <i className="fa-solid fa-circle"></i>
                                        <span>{movieInfo.total_time}</span>
                                        <i className="fa-solid fa-circle type_dot"></i>
                                        <span className="types">{types}</span>
                                    </li>
                                    <div className="rating_sec">
                                        <h5>Ratings:</h5>
                                        <div className="stars">
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star-half-stroke"></i>
                                        </div>
                                        <span>(4.7)</span>
                                        <span className="dot"><i className="fa-solid fa-circle"></i></span>
                                        <p>[ 12,225 Users ]</p>
                                    </div>
                                    <li>
                                        <p>
                                            {
                                                theaterName ? (
                                                    <a>{selectedShowDetails.language}</a>
                                                  ) : (
                                                    languages.map((language, index) => (
                                                        <a key={index}>{language}</a>
                                                    ))
                                                )
                                            }
                                        </p>
                                    </li>
                                    <ul>
                                        <button onClick={handleTrailer} className="trailer_btn"><span><i className="fa-regular fa-circle-play"></i>See Trailer</span></button>
                                        {bookingBtnShow && <button onClick={showData} className="booking_btn"><span><i className="fa-solid fa-ticket"></i> Book Ticket</span></button>}
                                    </ul>
                                    {
                                        // rateButtonShow && 
                                        <div className="rating_btn">
                                            <a onClick={handleRateBox}><i class="fa-solid fa-hand-point-right"></i>Rate Now</a>
                                            <div className={`rating_form ${rateBox ? 'active' : ''}`}>
                                                <ul>
                                                    {[1, 2, 3, 4, 5].map(star => (
                                                        <label key={star} onClick={() => handleStarClick(star)}>
                                                             <i className={`fa-solid fa-star ${star <= selectedRating ? (selectedRating === 5 ? 'glowing-star' : 'star') : 'inactive-star'}`}></i>
                                                        </label>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="item_share">
                                <div className="item_inner">
                                    <div className={`share_btn ${shareButtons ? 'active' : ''}`} onClick={handleShareButtons}>
                                        <img src="images/share.png" alt="share" />
                                    </div>
                                    <div className="share_options">
                                        <li className={shareButtons ? 'active' : ''}>
                                            <a onClick={copyLink}><img src="images/copy-icon.png" alt="copy" /></a>
                                            <span>Copy Link</span>
                                        </li>
                                        <li className={shareButtons ? 'active' : ''}>
                                            <a onClick={shareOnFacebook}><img src="images/facebook-icon.png" alt="copy" /></a>
                                            <span>Facebook</span>
                                        </li>
                                        <li className={shareButtons ? 'active' : ''}>
                                            <a onClick={shareOnWhatsapp}><img src="images/whatsapp-icon.png" alt="copy" /></a>
                                            <span>Whatsapp</span>
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shape">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#FFF" fill-opacity="1" d="M0,32L48,48C96,64,192,96,288,128C384,160,480,192,576,181.3C672,171,768,117,864,101.3C960,85,1056,107,1152,117.3C1248,128,1344,128,1392,128L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                        </div>
                    </div>
                }

                <div className="movie_info_sec">
                    <div className="sec_content">
                        <div className="movie_about">
                            <h2>About the <span>Movie</span></h2>
                            <p>{movieAbout}</p>
                        </div>
                        <div className="movie_cast_sec">
                            <h2>Movie <span>Casts</span></h2>
                            <div className="cast_slider_sec">
                                {
                                    casts &&
                                    <Swiper
                                        slidesPerView={'auto'}
                                        navigation={true}
                                        modules={[Navigation]}
                                        className="mySwiper"
                                    >
                                        {
                                            casts.map((cast, index) => 
                                                <SwiperSlide key={index}>
                                                    <div className="cast_box">
                                                        <div className="img_box"><img src={`${Member_Image_URL}/${cast.image}`} alt={cast.name} /></div>
                                                        <p>{cast.name}</p>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        }
                                    </Swiper>
                                }
                            </div>
                        </div>
                        <div className="movie_crew_sec">
                            <h2>Movie <span>Crew</span></h2>
                            <div className="crew_slider_sec">
                                {
                                    crews &&
                                    <Swiper
                                        slidesPerView={'auto'}
                                        navigation={true}
                                        modules={[Navigation]}
                                        className="mySwiper"
                                    >
                                        {
                                            crews.map((crew, index) => 
                                                <SwiperSlide>
                                                    <div className="crew_box">
                                                        <div className="img_box"><img src={`${Member_Image_URL}/${crew.image}`} alt={crew.name} /></div>
                                                        <p>{crew.name}</p>
                                                        {/* <span>Director</span> */}
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        }
                                    </Swiper>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="user_review_sec">
                    <div className="sec_inner">
                        <div className="sec_head">
                            <h2>Customer <span>Reviews</span></h2>
                            <a href="#"><span>See All<i class="fa-solid fa-arrow-right-long"></i></span></a>
                        </div>

                        <div className="review_slider_sec">
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={'auto'}
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <div className="quote_icon"><i class="fa-solid fa-quote-right"></i></div>
                                    <div className="review_box">
                                        <div className="review_top">
                                            <div className="user_img"><i class="fa-solid fa-user"></i></div>
                                            <h3>Sourish Mondal</h3>
                                        </div>
                                        <div className="review_message">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores placeat voluptas ad et nesciunt, iusto est maiores delectus repudiandae nihil dolorem omnis obcaecati tempora voluptate sed saepe aliquid dolorum. Quibusdam blanditiis</p>
                                        </div>
                                        <div className="review_bottom">
                                            <div className="user_review">
                                                <h4>Rated:</h4>
                                                <span>4/5</span>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                            <div className="review_date">
                                                <p>20 May, 2024</p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="quote_icon"><i class="fa-solid fa-quote-right"></i></div>
                                    <div className="review_box">
                                        <div className="review_top">
                                            <div className="user_img"><i class="fa-solid fa-user"></i></div>
                                            <h3>Sourish Mondal</h3>
                                        </div>
                                        <div className="review_message">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores placeat voluptas ad et nesciunt, iusto est maiores delectus repudiandae nihil dolorem omnis obcaecati tempora voluptate sed saepe aliquid dolorum. Quibusdam blanditiis</p>
                                        </div>
                                        <div className="review_bottom">
                                            <div className="user_review">
                                                <h4>Rated:</h4>
                                                <span>4/5</span>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                            <div className="review_date">
                                                <p>20 May, 2024</p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="quote_icon"><i class="fa-solid fa-quote-right"></i></div>
                                    <div className="review_box">
                                        <div className="review_top">
                                            <div className="user_img"><i class="fa-solid fa-user"></i></div>
                                            <h3>Sourish Mondal</h3>
                                        </div>
                                        <div className="review_message">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores placeat voluptas ad et nesciunt, iusto est maiores delectus repudiandae nihil dolorem omnis obcaecati tempora voluptate sed saepe aliquid dolorum. Quibusdam blanditiis</p>
                                        </div>
                                        <div className="review_bottom">
                                            <div className="user_review">
                                                <h4>Rated:</h4>
                                                <span>4/5</span>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                            <div className="review_date">
                                                <p>20 May, 2024</p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="quote_icon"><i class="fa-solid fa-quote-right"></i></div>
                                    <div className="review_box">
                                        <div className="review_top">
                                            <div className="user_img"><i class="fa-solid fa-user"></i></div>
                                            <h3>Sourish Mondal</h3>
                                        </div>
                                        <div className="review_message">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores placeat voluptas ad et nesciunt, iusto est maiores delectus repudiandae nihil dolorem omnis obcaecati tempora voluptate sed saepe aliquid dolorum. Quibusdam blanditiis</p>
                                        </div>
                                        <div className="review_bottom">
                                            <div className="user_review">
                                                <h4>Rated:</h4>
                                                <span>4/5</span>
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                            <div className="review_date">
                                                <p>20 May, 2024</p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>

                {/* <div className="related_movie_sec">
                    <div className="sec_head">
                        <h2>You may also <span>Like</span></h2>
                        <a href="#"><span>See All<i class="fa-solid fa-arrow-right-long"></i></span></a>
                    </div>

                    <div className="related_slider_sec">
                        <Swiper
                            grabCursor={true}
                            slidesPerView={'auto'}
                            navigation={true}
                            modules={[Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className="movie_box">
                                    <div className="box_img">
                                        <img src="images/Movie-1.jpg" alt="post" />
                                    </div>
                                    <div className="box_info">
                                        <h5>Avatar: The Way of Water</h5>
                                        <p>Sci-Fi / Action</p>
                                        <a><span><i className="fa-solid fa-ticket"></i> Book Ticket</span></a>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="movie_box">
                                    <div className="box_img">
                                        <img src="images/Movie-1.jpg" alt="post" />
                                    </div>
                                    <div className="box_info">
                                        <h5>Avatar: The Way of Water</h5>
                                        <p>Sci-Fi / Action</p>
                                        <a><span><i className="fa-solid fa-ticket"></i> Book Ticket</span></a>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="movie_box">
                                    <div className="box_img">
                                        <img src="images/Movie-1.jpg" alt="post" />
                                    </div>
                                    <div className="box_info">
                                        <h5>Avatar: The Way of Water</h5>
                                        <p>Sci-Fi / Action</p>
                                        <a><span><i className="fa-solid fa-ticket"></i> Book Ticket</span></a>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="movie_box">
                                    <div className="box_img">
                                        <img src="images/Movie-1.jpg" alt="post" />
                                    </div>
                                    <div className="box_info">
                                        <h5>Avatar: The Way of Water</h5>
                                        <p>Sci-Fi / Action</p>
                                        <a><span><i className="fa-solid fa-ticket"></i> Book Ticket</span></a>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="movie_box">
                                    <div className="box_img">
                                        <img src="images/Movie-1.jpg" alt="post" />
                                    </div>
                                    <div className="box_info">
                                        <h5>Avatar: The Way of Water</h5>
                                        <p>Sci-Fi / Action</p>
                                        <a><span><i className="fa-solid fa-ticket"></i> Book Ticket</span></a>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="movie_box">
                                    <div className="box_img">
                                        <img src="images/Movie-1.jpg" alt="post" />
                                    </div>
                                    <div className="box_info">
                                        <h5>Avatar: The Way of Water</h5>
                                        <p>Sci-Fi / Action</p>
                                        <a><span><i className="fa-solid fa-ticket"></i> Book Ticket</span></a>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="movie_box">
                                    <div className="box_img">
                                        <img src="images/Movie-1.jpg" alt="post" />
                                    </div>
                                    <div className="box_info">
                                        <h5>Avatar: The Way of Water</h5>
                                        <p>Sci-Fi / Action</p>
                                        <a><span><i className="fa-solid fa-ticket"></i> Book Ticket</span></a>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div> */}
            </BookingWrapper>

            <ModalTrailer trailerShow={trailerShow} setTrailerShow={setTrailerShow} trailer={trailer} />
            <ModalReview reviewModalShow={reviewModalShow} setReviewModalShow={setReviewModalShow} selectedRating={selectedRating} setRateBox={setRateBox}  />
            <MovieTimingInfo movieDatesModalShow={movieDatesModalShow} setMovieDatesModalShow={setMovieDatesModalShow} movieName={movieName} setBookingModalShow={setBookingModalShow} setSelectedShowDetails={setSelectedShowDetails} setBookedSeats={setBookedSeats} />
            <ModalBooking bookingModalShow={bookingModalShow} setBookingModalShow={setBookingModalShow} selectedShowDetails={selectedShowDetails} bookedSeats={bookedSeats} />
        </>
    );
}


export default MoviePage;