import { useEffect, useRef, useState } from "react";
import { BookingModalWrapper, CheckoutTermsModalBox, SeatCapacitySelect } from "../Styles/Modal-Style";
import axios from "axios";
import { Fetch_Admin_Commissions_URL, Fetch_Seats_Availble_URL, Fetch_Seats_URL, Fetch_Theater_Commissions_URL } from "../API/Api";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from 'swiper/modules';
import ModalCheckout from "./CheckoutModal";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';

import 'swiper/css';
import 'swiper/css/free-mode';



function ModalBooking({ bookingModalShow, setBookingModalShow, selectedShowDetails, bookedSeats }) {

    const [seatsData, setSeatsData] = useState([]);
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [checkoutTermsModalShow, setcheckoutTermsModalShow] = useState(false);
    const [checkoutModalShow, setcheckoutModalShow] = useState(false);
    const [groupedSeatsInfo, setGroupedSeatsInfo] = useState([]);
    const [sections, setSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState('');
    const [commissions, setCommissions] = useState({});
    const [theaterCommission, setTheaterCommission] = useState('');
    const [theaterCommissions, setTheaterCommissions] = useState({});
    const [theaterMultiCommissions, setTheaterMultiCommissions] = useState(false);
    const [seatCountModalShow, setSeatCountModalShow] = useState(false);
    const [selectedSeatCount, setSelectedSeatCount] = useState(1);
    const seatCountIcons = {
        1: "images/cycle-icon.jpg",
        2: "images/bike-icon.jpg",
        3: "images/auto-icon.jpg",
        4: "images/small-car-icon.png",
        5: "images/large-car-icon.png",
        6: "images/large-car-icon.png",
        7: "images/large-car-icon.png",
        8: "images/bus-icon.jpg",
        9: "images/bus-icon.jpg",
        10: "images/bus-icon.jpg",
    }


    useEffect(() => {
        
        if(bookingModalShow) {
            setSeatCountModalShow(true);
        }

    }, [selectedShowDetails]);
    

    useEffect(() => {

        axios.get(`${Fetch_Seats_Availble_URL}?id=${selectedShowDetails.screenId}&&movie=${selectedShowDetails.movieName}&&date=${selectedShowDetails.date}&&time=${selectedShowDetails.time}`)
        .then(res => {
            console.log(res);
            setSections(res.data.seatData);
            setSelectedSection(res.data.seatData[0].sec_name);
        })
        .catch(err => {
            console.log(err);
        })

    }, [selectedShowDetails]);

    useEffect(() => {

        axios.get(`${Fetch_Admin_Commissions_URL}?name=${selectedShowDetails.theaterName}`)
        .then(res => {
            console.log(res.data.commissions[0]);
            setCommissions(res.data.commissions[0]);
        })
        .catch(err => {
            console.log(err);
        })

    }, [selectedShowDetails]);

    useEffect(() => {

        axios.get(`${Fetch_Theater_Commissions_URL}?name=${selectedShowDetails.theaterName}`)
        .then(res => {
            console.log(res);
            if(res.data.commission_type == "Single Commission") {
                setTheaterMultiCommissions(false);
                setTheaterCommission(res.data.commission);
                setTheaterCommissions({});
            } else if(res.data.commission_type == "Multiple Commissions") {
                setTheaterMultiCommissions(true);
                setTheaterCommission('');
                const commissionArray = JSON.parse(res.data.commission);
                const formattedCommission = commissionArray.reduce((acc, commission) => {
                    acc[commission.range_slab] = commission.amount;
                    return acc;
                }, {});
                setTheaterCommissions(formattedCommission);
            }
        })
        .catch(err => {
            console.log(err);
        })

    }, [selectedShowDetails]);

    useEffect(() => {

        axios.get(`${Fetch_Seats_URL}?id=${selectedShowDetails.screenId}&&secName=${selectedSection}`)
        .then(res => {
            console.log(res);
            if (res.data.status == "200") {
                setSeatsData(res.data.seatData);
                setSelectedSeats([]);
            }
        })
        .catch(err => {
            console.log(err);
            setSeatsData([]);
        })

    }, [selectedShowDetails, selectedSection]);

    const indexToAlphabet = (index) => {
        let letters = '';
        while (index >= 0) {
            letters = String.fromCharCode((index % 26) + 65) + letters;
            index = Math.floor(index / 26) - 1;
        }
        return letters;
    };

    const parseSeatsString = (seatsString) => {
        return seatsString.split(',').map(seat => parseInt(seat.trim(), 10));
    };

    const parseBookedSeatsString = (bookedString) => {
        return bookedString ? bookedString.split(',').map(seat => seat.trim()) : [];
    };

    const getBookedSeatsForSection = (sectionName) => {
        const section = bookedSeats && bookedSeats.find(section => section.section === sectionName);
        return section ? parseBookedSeatsString(section.total_bookings) : [];
    };

    const handleSeatClick = (sectionName, seatNumber, price) => {
        const seatIdentifier = `${sectionName}-${seatNumber}`;

        const isSeatAlreadySelected = selectedSeats.some(seat => seat.identifier === seatIdentifier);

        if (!isSeatAlreadySelected && selectedSeats.length >= selectedSeatCount) {
            // Show a toaster notification
            toast.warn(`You can only select ${selectedSeatCount} seats. If you want more then please edit your limit.`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return; // Prevent further action
        }

        setSelectedSeats(prevSelectedSeats => {
            if (isSeatAlreadySelected) {
                return prevSelectedSeats.filter(seat => seat.identifier !== seatIdentifier);
            } else {
                return [...prevSelectedSeats, { sectionName, seatNumber, price: Number(price), identifier: seatIdentifier }];
            }
        });
    };

    const handleSeatCountClick  = (count) => {
        setSelectedSeatCount(count);
    };

    const handleSectionClick = (name) => {
        setSelectedSection(name);
    };

    function seatCountModalOpen() {
        setSeatCountModalShow(true);
    }

    function seatCountModalClose() {
        setSeatCountModalShow(false);
    }

    const handleBooking = (e) => {
        e.preventDefault();
    
        if (selectedSeats.length === 0) {
            // No seats selected, do not show checkout modal
            toast.error('No seat selected', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return; // Prevent further action
        } else if(selectedSeats.length < selectedSeatCount) {
            toast.info(`You haven't selected ${selectedSeatCount} seats yet.`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            }); // Prevent further action
            return; // Prevent further action
        }

        // Group seats by section and row
        const groupedSeats = selectedSeats.reduce((acc, seat) => {
            if (!acc[seat.sectionName]) {
                acc[seat.sectionName] = { rows: {}, price: seat.price, seatCount: 0 };
            }
            const rowIndex = seat.seatNumber.split('-')[0]; // Extract row index from seat number
            const seatNumber = seat.seatNumber.split('-')[1]; // Extract seat number without row index
            if (!acc[seat.sectionName].rows[rowIndex]) {
                acc[seat.sectionName].rows[rowIndex] = [];
            }
            acc[seat.sectionName].rows[rowIndex].push(seatNumber);
            acc[seat.sectionName].seatCount += 1;

            return acc;
        }, {});

        // Sort the seats within each row in ascending order
        for (const section in groupedSeats) {
            for (const row in groupedSeats[section].rows) {
                groupedSeats[section].rows[row].sort((a, b) => a - b);
            }
        }

        // Format grouped seats information for display
        const formattedGroupedSeats = Object.entries(groupedSeats).map(([sectionName, data]) => {
            const sectionSeats = selectedSeats.filter(seat => seat.sectionName === sectionName);
            const sectionTotalPrice = sectionSeats.reduce((acc, seat) => acc + seat.price, 0).toFixed(2);

            const commission = Object.keys(commissions).reduce((acc, range) => {
                const [min, max] = range.split(' to ').map(Number);
                if (data.price >= min && data.price <= max) {
                    acc = commissions[range];
                }
                return acc;
            }, 0);

            let theaterTicketCommission;
            if(theaterMultiCommissions) {
                theaterTicketCommission = Object.keys(theaterCommissions).reduce((acc, range) => {
                    const [min, max] = range.split(' to ').map(Number);
                    if (data.price >= min && data.price <= max) {
                        acc = parseFloat(theaterCommissions[range]);
                    }
                    return acc;
                }, 0);
            } else {
                theaterTicketCommission = parseFloat(theaterCommission);
            }
     
            return {
                sectionName,
                price: data.price, // This price might be static or derived differently
                sectionTotalPrice, // Total price for the section
                commission: commission * data.seatCount,
                theaterCommission: theaterTicketCommission * data.seatCount,
                seatCount: data.seatCount,
                selectedSeats: selectedSeats,
                rows: Object.entries(data.rows).map(([rowIndex, seats]) => ({
                    rowIndex,
                    seats: JSON.stringify(seats)
                }))
            };
        });

        // console.log(formattedGroupedSeats);

        // Set grouped seats information
        setGroupedSeatsInfo(formattedGroupedSeats);
        
        // Show checkout terms modal
        setcheckoutTermsModalShow(true);

    };
    
    function checkoutTermsModalAccept() {
        setcheckoutTermsModalShow(false);
        setcheckoutModalShow(true);
    };
    
    function checkoutTermsModalClose() {
        setcheckoutTermsModalShow(false);
    };

    const closeModal = () => {
        setBookingModalShow(false);
        localStorage.removeItem("Movie_name");
    }


    return (
        <>
            <BookingModalWrapper className={bookingModalShow ? 'active' : ''}>
                <div className="wrapper_inner">
                    <div className="top_part">
                        <div className="show_details">
                            <h3>{selectedShowDetails.theaterName} <span>[ {selectedShowDetails.screen} ]</span></h3>
                            <h5><span>Show Name :</span> {selectedShowDetails.movieName}</h5>
                            <li><p><span>Language :</span>{selectedShowDetails.language},</p> <p><span>Time :</span>{selectedShowDetails.time},</p> <p><span>Date :</span>{selectedShowDetails.date}</p> </li>
                        </div>
                        <div className="count_sec">
                            <div className="res_back_btn"><a onClick={() => setBookingModalShow(false)}><i className="fa-solid fa-arrow-left"></i></a></div>
                            <div className="count_box"><span>Seat Count :</span>{selectedSeatCount}<i className="fa-solid fa-pen-to-square" onClick={seatCountModalOpen}></i></div>
                        </div>
                    </div>
                    <div className="seat_layout_part">
                        <div className="seat_layout">
                            <Swiper
                                freeMode={true}
                                slidesPerView={'auto'}
                                modules={[FreeMode]}
                                className="mySwiper"
                                style={{ overflow: 'visible' }}
                            >
                                <SwiperSlide>
                                    <div className="screen_box">
                                        <div className="display_image">
                                            <img src="/images/Screen-Display.svg" alt="Display" />
                                        </div>
                                        <div className="seat_sections">
                                            {
                                                seatsData && seatsData.map((seatData, index) => {
                                                    const bookedSeatsForSection = getBookedSeatsForSection(seatData.sec_name);

                                                    return (
                                                        <div className="section" key={index}>
                                                            <div className="sec_name">
                                                                <h5>{seatData.sec_name}</h5>
                                                                <p>( Ticket price {seatData.price}/- )</p>
                                                            </div>
                                                            <div className="sec_seat_rows">
                                                                {
                                                                    (seatData.rows).map((seats, idx) => {
                                                                        const gapSeats = parseSeatsString(seats.gap_seats || '');
                                                                        const gapAmounts = parseSeatsString(seats.gap_amounts || '');
                                                                        return (
                                                                            <div className="seat_row" key={idx}>
                                                                                <div className="seats">
                                                                                    {
                                                                                        Array.from({ length: seats.seats }, (_, i) => {
                                                                                            const isGap = gapSeats.includes(i + 1);
                                                                                            const gapIndex = gapSeats.indexOf(i + 1);
                                                                                            const marginLeft = isGap && gapIndex !== -1 ? `${gapAmounts[gapIndex] * 40}px` : '0';
                                                                                            const seatNumber = `${indexToAlphabet(idx)}-${i + 1}`;
                                                                                            const isBooked = bookedSeatsForSection.includes(seatNumber);

                                                                                            return (
                                                                                                <li key={i}
                                                                                                    className={` ${selectedSeats.some(seat => seat.identifier === `${seatData.sec_name}-${indexToAlphabet(idx)}-${i + 1}`) ? 'selected' : ''} ${isBooked ? 'booked' : ''}`} 
                                                                                                    style={{
                                                                                                        '--gap': gapIndex !== -1 ? gapAmounts[gapIndex] : 0,
                                                                                                        '--starting': i === 0 ? seats.starting : 0
                                                                                                    }} 
                                                                                                    onClick={() => handleSeatClick(seatData.sec_name, `${indexToAlphabet(idx)}-${i + 1}`, seatData.price)}>
                                                                                                    <i className="fa-solid fa-couch"></i>
                                                                                                    <p>{i + 1}</p>
                                                                                                </li>
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                <span className="index">{indexToAlphabet(idx)}</span>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide></SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                    <div className="bottom_part">
                        <div className="indicator_sec">
                            <li className="available">
                                <i className="fa-solid fa-couch"></i>
                                <p>Available</p>
                            </li>
                            <li className="booked">
                                <i className="fa-solid fa-couch"></i>
                                <p>Booked</p>
                            </li>
                        </div>
                        <div className="btn_sec">
                            <a className="close_btn" onClick={closeModal}><span><i className="fa-solid fa-xmark"></i>Close</span></a>
                            <a className={`booking_btn ${selectedSeats.length > 0 ? 'active' : '' }`} onClick={handleBooking}><span><i className="fa-solid fa-ticket"></i>Book Now</span></a>
                        </div>
                    </div>
                </div>

                <SeatCapacitySelect className={seatCountModalShow ? 'active' : ''}>
                    <div className={`wrapper_innner ${seatCountModalShow ? 'active' : ''}`}>
                        <div className="modal_close"><a onClick={seatCountModalClose}></a></div>
                        <div className="heading_part">
                            <h3>How Many Seats You Want to Book ?</h3>
                        </div>
                        <div className="middle_part">
                            <div className="icon_box">
                                <img src={selectedSeatCount ? seatCountIcons[selectedSeatCount] : 'images/cycle-icon.jpg'}  alt="Selected Seat" />
                            </div>
                            <div className="selection_btn_sec">
                                {
                                    [...Array(10).keys()].map((i) => (
                                        <a key={i + 1} className={selectedSeatCount === i + 1 ? 'selected' : ''} onClick={() => handleSeatCountClick(i + 1)} >{i + 1}</a>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="section_part">
                            <div className="part_inner">
                                {
                                    sections && sections.map((section, index) => (
                                        <div className="section_btn" key={index}>
                                            <div className={`btn_inner ${selectedSection == section.sec_name ? 'selected' : ''}`} onClick={() => handleSectionClick(section.sec_name)}>
                                                <h5>{section.sec_name}</h5>
                                                <b>-</b>
                                                <span><i className="fa-solid fa-indian-rupee-sign"></i> {section.price}</span>
                                                <p>{section.available_seats} Seats Available</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="bottom_part">
                            <button onClick={seatCountModalClose}>Select Seats</button>
                        </div>
                    </div>
                </SeatCapacitySelect>

                <CheckoutTermsModalBox className={checkoutTermsModalShow ? 'active' : ''}>
                    <div className={`terms_box ${checkoutTermsModalShow ? 'active' : ''}`}>
                        <div className="heading_part">
                            <h3>Terms & Conditions</h3>
                            <a onClick={checkoutTermsModalClose}><i className="fa-solid fa-xmark"></i></a>
                        </div>
                        <div className="body_part">
                            <div className="part_inner">
                                <li>
                                    <span>1.</span>
                                    <p>Please pick up your tickets at least 20 mins before showtime to avoid rush at the counter.</p>
                                </li>
                                <li>
                                    <span>2.</span>
                                    <p>Outside food & beverages are not allowed inside the cinema premises.</p>
                                </li>
                                <li>
                                    <span>3.</span>
                                    <p>Ticket is compulsory for children of 3 years & above.</p>
                                </li>
                                <li>
                                    <span>4.</span>
                                    <p>Ticket for <b>'A'</b> rated movie should not be purchased for people under 18 years of age. There won't be a refund for tickets booked in such cases.</p>
                                </li>
                                <li>
                                    <span>5.</span>
                                    <p>Handbags, Laptops/Tabs, cameras and all other electronic items are not allowed inside cinema premises.</p>
                                </li>
                                <li>
                                    <span>6.</span>
                                    <p>Smoking is strictly not permitted inside the cinema premises. Cigarettes/lighters/matchsticks/Gutkha/Pan masala etc. will not be allowed.</p>
                                </li>
                                <li>
                                    <span>7.</span>
                                    <p>Cinema reserves the Right of Admission.</p>
                                </li>
                                <li>
                                    <span>8.</span>
                                    <p>People under the influence of Alcohol/Drugs will not be allowed inside the cinema premise.</p>
                                </li>
                                <li>
                                    <span>9.</span>
                                    <p>Tickets once purchased cannot be exchanged or adjusted/transferred for any other shows.</p>
                                </li>
                                <li>
                                    <span>10.</span>
                                    <p>Screenshots or forwarded messages will not be accepted during the entry at the Cinema.</p>
                                </li>
                            </div>
                        </div>
                        <div className="btn_part">
                            <button onClick={checkoutTermsModalClose}><span><i className="fa-regular fa-circle-xmark"></i>Decline</span></button>
                            <button onClick={checkoutTermsModalAccept}><span><i className="fa-regular fa-circle-check"></i>Accept</span></button>
                        </div>
                    </div>
                </CheckoutTermsModalBox>

                <ModalCheckout checkoutModalShow={checkoutModalShow} setcheckoutModalShow={setcheckoutModalShow} selectedShowDetails={selectedShowDetails} seatsInfo={groupedSeatsInfo} setSelectedSeats={setSelectedSeats} />
            </BookingModalWrapper>
        </>
    );
}


export default ModalBooking;