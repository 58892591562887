import { useEffect, useRef, useState } from "react";
import { InfoWrapper } from "../Styles/Modal-Style";
import axios from "axios";
import { Fetch_Booked_Seats_URL, Movie_Dates_URL, Movie_Info_Dates_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function MovieTimingInfo({movieDatesModalShow, setMovieDatesModalShow, movieName, setBookingModalShow, setSelectedShowDetails, setBookedSeats}) {

    const [showButtons, setShowButtons] = useState(false);
    const [count, setCount] = useState(false);
    const [dates, setDates] = useState([]);
    const [shows, setShows] = useState([]);
    const [showCount, setShowCount] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedFormattedDate, setSelectedFormattedDate] = useState('');
    const datePanelRef = useRef(null);

    const scrollLeft = () => {
        datePanelRef.current.scrollBy({ left: -150, behavior: 'smooth' });
    };

    const scrollRight = () => {
        datePanelRef.current.scrollBy({ left: 150, behavior: 'smooth' });
    };

    useEffect(() => {

        axios.get(`${Movie_Dates_URL}?name=${movieName}`)
        .then(res => {
            // console.log(res);
            if (res.data.count > 0){
                const formattedDates = res.data.dates.map(date => {
                    const [day, month] = date.date.split(',')[0].split(' ');
                    return {
                        date: date.date, // Ensure date is a string
                        formatted_date: `${day} ${month}`
                    };
                });
                setSelectedDate(formattedDates[0].date);
                setSelectedFormattedDate(formattedDates[0].formatted_date);
                setCount(true);
                setDates(formattedDates);
            } else {
                setCount(false);
                setDates([]);
            }
        })
        .catch(err => {
            setCount(false);
            setDates([]);
        })

    }, [movieDatesModalShow]);

    useEffect(() => {
        const checkOverflow = () => {
            if (datePanelRef.current) {
                setShowButtons(datePanelRef.current.scrollWidth > datePanelRef.current.clientWidth);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [dates]);

    useEffect(() => {
        if(selectedDate){
            axios.get(`${Movie_Info_Dates_URL}?date=${encodeURIComponent(selectedDate)}&name=${encodeURIComponent(movieName)}`)
            .then(res => {
                // console.log(res);
                if(res.data.counts > 0){
                    setShowCount(true);
                    setShows(res.data.shows);
                } else {
                    setShowCount(false);
                    setShows([]);
                }
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [selectedDate]);

    const handleDateClick = (formattedDate, date) => {
        setSelectedDate(date);
        setSelectedFormattedDate(formattedDate);
    };

    const closeInfoScreen = () => {
        setMovieDatesModalShow(false);
    }

    const handleBooking = (theater_name, time, language, screen, screen_id, date) => {

        const inputs = {
            theater: theater_name,
            screen: screen,
            screen_id: screen_id,
            movie_name: movieName,
            date: date,
            time: time
        };

        axios.post(Fetch_Booked_Seats_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            if(res.data.booking_status === true) {
                setSelectedShowDetails({movieName: movieName, theaterName: theater_name, screen: screen, screenId: screen_id, language: language, time:time, date:date});
                setBookedSeats(res.data.booked);
                setMovieDatesModalShow(false);
                setBookingModalShow(true);
            } else {
                toast.warn('Sorry !!! Online booking is not available for this show.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        })
        .catch(err => {
            // console.log(err);
            setBookedSeats([]);
        })
    }; 

    return(
        <>
            <InfoWrapper className={movieDatesModalShow ? 'active' : ''}>
                <div className={`info_modal ${movieDatesModalShow ? 'active' : ''}`}>
                    <div className="modal_inner">
                        <div className="top_part">
                            <h3>{movieName}</h3>
                            <span onClick={closeInfoScreen}><i className="fa-solid fa-xmark"></i></span>
                        </div>
                        <div className="date_panel_wrapper">
                            <div className="arrow_btn left">
                                {showButtons && <a className="scroll_btn" onClick={scrollLeft}><i className="fa-solid fa-arrow-left-long"></i></a>}
                            </div>
                            <div className="date_panel" ref={datePanelRef}>
                                {dates.map((date, index) => (
                                    <label key={index} onClick={() => handleDateClick(date.formatted_date, date.date)} className={date.formatted_date === selectedFormattedDate ? 'selected' : ''}>
                                        <li>
                                            <span></span>
                                            <span></span>
                                            <p>{date.formatted_date}</p>
                                        </li>
                                    </label>
                                ))}
                            </div>
                            <div className="arrow_btn right">
                                {showButtons && <a className="scroll_btn" onClick={scrollRight}><i className="fa-solid fa-arrow-right-long"></i></a>}
                            </div>
                        </div>

                        {
                            showCount &&
                            <div className="movie_info_box">
                                <div className="infobox_inner">
                                    {
                                        shows && shows.map((show, index) => 
                                            <div className="info_box" key={index}>
                                                <div className="movie_name">
                                                    <p>{show.theater_name}</p>
                                                </div>
                                                <div className="movie_times">
                                                    {
                                                        show.timings && show.timings.map((timing, i) => 
                                                            <li key={i} onClick={() => handleBooking(show.theater_name, timing.time, timing.language, timing.screen, timing.screen_id, timing.date)}>
                                                                <a>{timing.time}</a>
                                                                <span><p>{timing.language}</p></span>
                                                            </li>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </InfoWrapper>
        </>
    );
}


export default MovieTimingInfo;