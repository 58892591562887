import { styled } from "@mui/material";


export const SuccessWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 50px 100px;

    .wrapper_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .page_icon {
            position: relative;
            display: flex;

            img {
                position: relative;
                width: 225px;
            }
        }

        .page_content {
            position: relative;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            h3 {
                position: relative;
                font-family: 'Oleo Script', cursive;
                font-size: 40px;
                color: rgb(2, 192, 255);
            }

            h5 {
                position: relative;
                margin-top: 3px;
                font-size: 22px;
                font-weight: 600;
                color: #4BB543;
            }

            p {
                position: relative;
                margin-top: 20px;
                font-size: 17px;
                font-weight: 500;
                color: rgb(165, 165, 165);

                span {
                    color: #E5322D;
                }
            }
        }
    }
`;

export const ErrorWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 50px 100px;

    .wrapper_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .page_icon {
            position: relative;
            display: flex;

            img {
                position: relative;
                width: 225px;
            }
        }

        .page_content {
            position: relative;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            h3 {
                position: relative;
                font-family: 'Oleo Script', cursive;
                font-size: 40px;
                color: #E5322D;
            }

            h5 {
                position: relative;
                margin-top: 3px;
                font-size: 20px;
                font-weight: 500;
                color: #E5322D;
            }

            p {
                position: relative;
                margin-top: 20px;
                font-size: 17px;
                font-weight: 500;
                color: rgb(165, 165, 165);

                span {
                    color: #E5322D;
                }
            }
        }
    }
`;